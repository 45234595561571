@tailwind base;
@tailwind components;
@tailwind utilities;

.foundation-section {
  &__container {
    @apply bg-light-green mt-4 w-full min-w-full h-full min-h-full pt-8 md:pt-24 px-6 md:px-24 pb-10 md:pb-24;
  }

  &__group {
    @apply flex flex-col gap-0 md:gap-16 md:flex-row md:items-center;
    &--item {
      @apply mt-10 w-full md:w-1/2;
    }
  }

  &__description {
    @apply text-primary font-normal text-base md:marker:text-2xl leading-6 md:text-lg mt-10;
  }
}