@tailwind base;
@tailwind components;
@tailwind utilities;

.how-section {
  &__container {
    @apply bg-tertiary flex flex-col md:flex-row mt-4 w-full min-w-full pt-0 px-6 h-full min-h-full pb-0 md:px-24;
  }

  &__title {
    @apply text-primary text-sm md:text-base font-bold leading-4 tracking-widest mt-8 md:mt-0;
  }

  &__subtitle {
    @apply font-bold text-3xl md:text-[32px] leading-9 md:leading-[48px] mt-4 w-64 md:w-full text-primary pr-0 md:pr-40;
  }
}