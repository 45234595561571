@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

@layer base {
  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/assets/fonts/GraphikRegular.otf) format('otf');
  }
  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/assets/fonts/GraphikMedium.otf) format('otf');
  }
  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/assets/fonts/GraphikSemibold.otf) format('otf');
  }
  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/assets/fonts/GraphikBold.otf) format('otf');
  }
}

.test {
  &--card {
    @apply bg-slate-50;
  }
}

